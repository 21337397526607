import { render, staticRenderFns } from "./petlist.vue?vue&type=template&id=9ab81412&scoped=true&"
import script from "./petlist.vue?vue&type=script&lang=js&"
export * from "./petlist.vue?vue&type=script&lang=js&"
import style0 from "../list/list.css?vue&type=style&index=0&id=9ab81412&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ab81412",
  null
  
)

export default component.exports