

export default {

    name: "order",

    created() {
        let t = this;

        t.dict.status = listToDict(t.list.status);

        t.dict.return_status = listToDict(t.list.return_status);

        t.loadData();
    },

    methods: {

        loadData() {

            let t = this;

            let p = getURLParams();

            if (!p.id) {
                t.$router.replace('/orders');
                return;
            }

            axios.get('order/' + p.id).then(res => {
                if (res.data.status == 1) {
                    let order = res.data.options.item;
                    if (order.address)
                        order.address = JSON.parse(order.address);
                    else
                        order.address = {};
                    t.orderForm = getFields(order, ['status', 'remark']);
                    t.returnForm = getFields(order, ['return_amount']);
                    t.o = order;
                }
            });

        },

        setView(view) {
            this.view = view;
            this.errMsg = "";
        },

        saveOrderForm() {
            let t = this;

            if (t.busying)
                return;

            let f = t.orderForm;

            t.errMsg = "";

            if (!f.status) {
                t.errMsg = "请设置订单状态"
                return;
            }

            t.busying = true;

            axios.put('order/' + t.o.id, f).then(res => {
                t.busying = false;

                if (res.data.status == 1) {
                    A.toast('保存成功');
                }
            });

        },

        decline() {

            let t = this;

            if (t.busying)
                return;

            A.show('您选择了拒绝该退款申请', '退款确认', 'info').then(res => {
                if (res.isConfirmed) {

                    t.busying = true;

                    axios.post('order/declineRefund/' + t.o.id).then(res => {
                        t.busying = false;

                        if (res.data.status == 1) {
                            t.loadData();
                        } else if (res.data.status == 2) {
                            A.show('订单状态异常，请联系技术人员检查', '数据异常', 'error');
                        }

                        t.$forceUpdate();
                    });

                }
            });
        },

        refund() {
            let t = this;

            if (t.busying)
                return;

            let f = t.returnForm;

            t.errMsg = "";

            if (!f.return_amount) {
                t.errMsg = "请输入退款金额";
                return;
            }

            if (Number(f.return_amount) > Number(t.o.payment_amount)) {
                t.errMsg = "退款金额不得大于订单金额￥" + t.o.payment_amount;
                return;
            }

            A.show('退款金额为: ￥' + f.return_amount + '，请谨慎确认', '退款确认', 'info').then(res => {
                if (res.isConfirmed) {

                    t.busying = true;

                    axios.post('order/confirmRefund/' + t.o.id, f).then(res => {

                        t.busying = false;

                        if (res.data.status == 1) {
                            t.o.return_status = 98;
                        }
                        else if (res.data.status == 2) {
                            A.show('订单状态异常，请联系技术人员检查', '数据异常', 'error');
                        } else if (res.data.status == 3) {
                            A.show('退款金额不得大于订单金额', '数据异常', 'error');
                        } else if (res.data.status == 4) {
                            A.show('订单状态异常，请联系技术人员检查', '数据异常', 'error');
                        }

                        t.$forceUpdate();
                    });

                }
            });
        },


    },
    data() {
        return {

            view: 'info',

            list: {
                status: [
                    { id: 1, name: '待支付' },
                    { id: 2, name: '待处理' },
                    { id: 3, name: '已送出' },

                    { id: 99, name: '已取消' },
                ],
                return_status: [
                    {
                        id: 1,
                        name: '要求退款',
                    },
                    {
                        id: 2,
                        name: '退款失败',
                    },
                    {
                        id: 3,
                        name: '已退款',
                    },
                    { id: 98, name: '退款中' },
                    {
                        id: 99,
                        name: '拒绝退款',
                    }],
            },

            dict: {
                status: {},
            },

            o: null,

            errMsg: "",

            busying: false,

            orderForm: {
                'status': null,
                'remark': ''
            },

            returnForm: {
                'amount': '',
            },

        };
    },
};
