


export default {

    created() {
        let t = this;

        t.loadData();

    },
    methods: {

        loadData() {

            let t = this;

            axios.get('petCategory').then(res => {
                if (res.data.status == 1) {
                    res.data.options.items.forEach(item => {
                        item.showActions = false;
                    });
                    t.list.item = res.data.options.items;
                    t.dict.parent = listToDict(t.list.item);
                }
            });


        },

        addItem() {
            let t = this;

            t.itemForm = mirror(t._baseForm);

            t.openModal('editor');
        },

        editItem(item) {
            let t = this;

            t.itemForm = mirror(item);

            t.openModal('editor');
        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        saveItemForm() {

            let t = this;

            if (t.busying)
                return;

            let f = t.itemForm;

            t.errMsg = "";

            if (!f.name) {
                t.errMsg = "请输入名称";
                return;
            }

            function _save(){

                t.busying = true;

                axios.save('petCategory', f).then(res => {

                    t.busying = false;;

                    if (res.data.status == 1) {
                        t.closeModal();
                        t.loadData();
                        A.toast('保存成功');
                    }
                });
            }

            if(f.blob_img){

                t.busying = true;

                let fd = new FormData();

                fd.append('image', t.imgBlobDict[f.blob_img]);

                axios.save('petCategory/saveImage', fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                    if(res.data.status == 1){
                        delete f.blob_img;
                        f.img_url = res.data.options.img_url;
                        _save();
                    }
                });
            }
            else
                _save();

        },

        delItem(item) {
            let t = this;

            A.safety('确认要移除该选项吗？').then(function (res) {
                if (res.isConfirmed) {
                    axios.delete('petCategory/' + item.id).then(res => {
                        if (res.data.status == 1) {
                            t.list.item.remove(item);
                        }
                    });
                }
            });
        },


        closeCropper() {
            $('.cropper-item').hide();
        },

        confirmCropper() {

            let t = this;

            t.cropper.getCroppedCanvas({
                minHeight: 80,
                minWdith: 80,
                maxHeight: 300,
                maxWdith: 300,
                fillColor: '#fff',
            }).toBlob((blob) => {
                let blobUrl = getObjectURL(blob);
                t.itemForm.blob_img = blobUrl;
                t.imgBlobDict[blobUrl] = blob;
                t.closeCropper();
                t.$forceUpdate();
            });
        },

        startUpload() {

            let t = this;

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                const image = document.getElementById('cropperImg');

                image.src = blobUrl;

                if (t.cropper) {
                    t.cropper.destroy();
                    t.cropper = null;
                }

                image.onload = () => {
                    t.cropper = new Cropper(image, {
                        aspectRatio: 1,
                        autoCropArea: 1,
                        crop(event) {
                        },
                    });
                    $('.cropper-item').show();
                }

                event.target['value'] = '';


            };

            ImageUploader.openSelector();
        },

        onSelectParent(e) {
            let t = this;
            t.parentValue = e.value;
            t.itemForm.parent_id = t.parentValue;
            t.showParentSelector = false;
        }

    },
    data() {
        return {
            errMsg: "",
            list: {
                item: [],
                parent: []
            },

            dict: {
                parent: {},
            },

            itemForm: {
                'name': '',
                'blob_img': '',
                'index': '',
            },

            _baseForm: {
                'name': '',
                'blob_img': '',
                'index': '',
            },

            busying: false,

            modal: "",

            imgBlobDict: {},

            showParentSelector: false,

            parentValue: null,

            options: [
                { text: '一级分类', value: null }
            ],

        };
    },
};
