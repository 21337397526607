

export default {


    created() {
        this.loadSelectorData();
    },

    activated() {
        let t = this;

        t.loadData();
    },

    methods: {

        loadSelectorData() {

            let t = this;

            axios.get('category').then(res => {
                if (res.data.status == 1) {
                    t.list.category = res.data.options.items;
                    t.dict.category = listToDict(t.list.category);

                    let options = [];

                    t.list.category.forEach(item => {
                        if (item.parent_id) {
                            let p = t.dict.category[item.parent_id];

                            if (p) {
                                if (!p.children)
                                    p.children = [];
                            }

                            p.children.push(item);

                        }
                        else {
                            options.push(item);
                        }
                    });

                    t.list.category.unshift({ id: null, name: '所有分类' });

                    t.options = options;
                }
            });

        },

        loadData() {

            let t = this;

            let s = t.search;

            let params = {};

            if (s.text)
                params.keywords = s.text;

            if (s.category)
                params.cate_id = s.category.id;

            axios.get('goods', { params: params }).then(res => {
                if (res.data.status == 1) {
                    res.data.options.items.forEach(item => {
                        item.showActions = false;
                    });
                    t.list.item = res.data.options.items;
                }
            });

        },

        newItem() {
            this.$router.push('/item?id=new');
        },

        goItem(item) {
            this.$router.push('/item?id=' + item.id);
        },

        sort(list, index, offset) {

            if (list.length == 1)
                return;

            let t = this;

            if (t.busying)
                return;

            let targetIndex = index + offset;
            let currentIndex = index;

            if (targetIndex >= 0 && targetIndex < list.length) {

                var currentItem = list[currentIndex];
                var targetItem = list[targetIndex];

                var currentIdx = currentItem.index;
                var targetIdx = targetItem.index;

                //交换在数组中的位置
                list[targetIndex] = currentItem;
                list[currentIndex] = targetItem;

                //交换index字段的值
                currentItem.index = targetIdx;
                targetItem.index = currentIdx;

                let params = {
                    'id': currentItem.id,
                    'index': currentItem.index,
                    'id2': targetItem.id,
                    'index2': targetItem.index
                };

                t.busying = true;

                axios.post('goods/saveIndex', params).then(res => {

                    t.busying = false;

                    t.$forceUpdate();

                    if (res.data.status == 1) {

                    }
                });
            }

        },

        delItem(item) {

            let t = this;

            A.safety('确定要删除该商品吗？').then(function (res) {
                if (res) {
                    axios.delete('goods/' + item.id).then(res => {
                        if (res.data.status == 1) {
                            t.list.item.remove(item);
                        }
                    });
                }
            });

        },

        onSearch() {
            let t = this;

            if (t.searchTimeoutID) {
                clearTimeout(t.searchTimeoutID);
                t.searchTimeoutID = null;
            }

            t.searchTimeoutID = setTimeout(() => {
                t.loadData();
            }, 300);
        },

        onSelectCategory(e) {
            let t = this;
            t.categoryValue = e.value;
            t.search.category = e.selectedOptions[0];
            t.showCategorySelector = false;
            t.loadData();
        }

    },

    data() {
        return {
            list: {
                category: [],
                item: []
            },

            dict: {
                category: {}
            },

            search: {
                text: '',
                category: null,
            },

            searchTimeoutID: null,

            showCategorySelector: false,

            categoryValue: null,

            options: [
            ],
        };
    },
};
