
import AppNav from "./components/nav";
import Vue from 'vue'
export default {
  name: "Index",
  components: {
    AppNav,
  },
  data() {
    return {
      disabledScroll: false
    };
  },
  methods: {
    afterLogin() {
      axios.defaults.headers.common["Token"] = this.U.token;
    }
  },
  created() {

    var t = this;

    Vue.prototype.startUrl = location.href;

    let _post = axios.post;
    let _put = axios.put;

    axios.post = function (url, data, other) {
      let d = mirror(data);
      delete d.showActions;

      if (/FormData/.test(data.toString())) {
        d = data;
      }

      return _post(url, d, other);
    }

    axios.put = function (url, data, other) {
      let d = mirror(data);
      delete d.showActions;
      if (/FormData/.test(data.toString())) {
        d = data;
      }

      return _put(url, d, other);
    }

    axios.save = function (url, data, other) {
      let d = mirror(data);
      delete d.showActions;

      if (/FormData/.test(data.toString())) {
        d = data;
      }

      if (d.id)
        return _put(url + '/' + d.id, d, other);
      else
        return _post(url, d, other);

    }

    axios.defaults.baseURL = 'https://i.shan-huan.com/m/';

    // if (/localhost/.test(location.href)) {
    //   axios.defaults.baseURL = 'http://localhost/mll/api/public/m/';
    // }

    Vue.prototype.afterLogin = this.afterLogin;

    Vue.prototype.busying = false;

    Vue.prototype.toggle = {
      expandedNav: true
    };

    if (S.get('_m')) {
      Vue.prototype.U = S.get('_m');
      axios.defaults.headers.common["Token"] = this.U.token;
    }
    else {
      Vue.prototype.U = {
        name: '',
        avatar_url: '',
        token: '',
        id: '',
        open_id: '',
      };
      t.$router.replace('/login');
    }


  },
  mounted() {
    let t = this;
  }

};
