


export default {

    created() {
        this.loadSelectorData();
        this.loadData();
    },

    activated() {
    },

    methods: {

        loadSelectorData() {

            let t = this;

            axios.get('petCategory').then(res => {
                if (res.data.status == 1) {
                    t.list.category = res.data.options.items;
                    t.dict.category = listToDict(t.list.category);

                    let options = [];

                    t.list.category.forEach(item=>{
                        if(item.parent_id)
                        {
                            let p = t.dict.category[item.parent_id];

                            if(p){
                                if(!p.children)
                                p.children = [];
                            }

                            p.children.push(item);

                        }
                        else{
                            options.push(item);
                        }
                    });

                    t.options = options;

                }
            });

        },

        loadData() {

            let t = this;

            let id = getURLParams().id;

            if (id != 'new') {

                axios.get('pet/' + id).then(res => {

                    if (res.data.status == 1) {

                        let item = res.data.options.item;

                        item.price = Number(item.price);

                        item.o_price = Number(item.o_price);

                        mirror(item, t.infoForm);

                        if (item.img_urls) {
                            let arr = item.img_urls.split(',');
                            let imgs = [];
                            arr.forEach(src => {
                                imgs.push({
                                    src: src,
                                    showActions: false
                                });
                            });
                            t.infoForm.imgs = imgs;
                        }

                        if (item.content) {
                            let arr = [];

                            $(item.content).each((index, element) => {
                                if (element.innerHTML && element.innerHTML.trim().toLowerCase() != '<br>') {
                                    let isImg = element.innerHTML.indexOf('upload-img') > -1;
                                    let isVideo = element.innerHTML.indexOf('upload-video') > -1;

                                    if (!isImg && !isVideo)
                                        arr.push({ 'content': element.innerHTML, 'type': 'text' });
                                    else {
                                        let type = '';
                                        if (isImg)
                                            type = 'img';
                                        if (isVideo)
                                            type = 'video';
                                        let res = /src="(.+?)"/.exec(element.innerHTML);
                                        arr.push({ 'content': res[1], 'type': type });
                                    }
                                }
                            });
                            item.contentArr = arr;
                        }

                        if (!item.contentArr)
                            item.contentArr = [];

                        if (item.options) {
                            item.options.forEach(option => {
                                option.labelForm = { 'name': '', id: null };
                                option.editing = false;
                                option.showActions = false
                                option.errMsg = "";
                                option.busying = false;
                            });
                        }
                        else {
                            item.options = [];
                        }

                        t.item = item;
                    }
                });

            } else {

                let item = {
                    name: '',
                    price: '',
                    imgs: [],
                    contentArr: [],
                };

                t.infoForm = item;

                t.item = item;
            }
        },

        setView(view) {
            this.view = view;
            this.errMsg = "";
        },

        optionSort(list, index, offset) {

            if (list.length == 1)
                return;

            let t = this;

            if (t.busying)
                return;

            let targetIndex = index + offset;
            let currentIndex = index;

            if (targetIndex >= 0 && targetIndex < list.length) {

                var currentItem = list[currentIndex];
                var targetItem = list[targetIndex];

                var currentIdx = currentItem.index;
                var targetIdx = targetItem.index;

                //交换在数组中的位置
                list[targetIndex] = currentItem;
                list[currentIndex] = targetItem;

                //交换index字段的值
                currentItem.index = targetIdx;
                targetItem.index = currentIdx;

                let params = {
                    'id': currentItem.id,
                    'index': currentItem.index,
                    'id2': targetItem.id,
                    'index2': targetItem.index
                };

                t.busying = true;

                axios.post('option/saveIndex', params).then(res => {

                    t.busying = false;

                    t.$forceUpdate();

                    if (res.data.status == 1) {

                    }
                });
            }
        },

        sort2(list, index, offset) {

            if (list.length == 1)
                return;

            let t = this;
            let targetIndex = index + offset;
            let currentIndex = index;

            if (targetIndex >= 0 && targetIndex < list.length) {

                var currentItem = list[currentIndex];
                var targetItem = list[targetIndex];

                //交换在数组中的位置
                list[targetIndex] = currentItem;
                list[currentIndex] = targetItem;

            }

            t.$forceUpdate();

        },

        delImg(img) {

            let t = this;

            A.safety('确定需要删除该图片吗？').then(function (res) {
                if (res.isConfirmed) {
                    t.infoForm.imgs.remove(img);
                }
            });

        },

        saveInfoForm() {

            let t = this;

            let f = t.infoForm;

            if (t.busying)
                return;

            t.errMsg = "";

            if (f.imgs.length == 0) {
                t.errMsg = "请上传宠物图片";
                return;
            }

            if (!f.cate_id) {
                t.errMsg = "请选择宠物种类";
                return;
            }

            if (!f.name) {
                t.errMsg = "请输入宠物名称";
                return;
            }

            if (!f.summary) {
                t.errMsg = "请输入宠物描述";
                return;
            }

            function _save() {

                let form = getFields(f, ['cate_id', 'name','gender','breed','age','color','weight','neutered','vaccinated','adoption_location','adoption_requirement','append','wechat','wechat_img_url', 'adoption_fee', 'summary']);

                let imgUrls = [];

                f.imgs.forEach((img) => {
                    imgUrls.push(img.src);
                });

                form.img_urls = imgUrls.join(',');

                t.busying = true;

                if (t.item.id) {
                    axios.put('pet/' + t.item.id, form).then(res => {
                        t.busying = false;

                        if (res.data.status == 1) {
                            A.toast('保存成功');
                        }
                    });
                }
                else
                    axios.post('pet', form).then(res => {
                        t.busying = false;

                        if (res.data.status == 1) {
                            let id = res.data.options.id;
                            t.$router.replace('/petitem?id=' + id);
                            A.toast('保存成功');
                        }
                    });

            }

            let idx = 0;

            let fd = new FormData();

            let imgIndexs = [];

            f.imgs.forEach((img, i) => {
                if (img.waitting_upload == 1) {
                    let blobUrl = img.src;
                    imgIndexs.push(i);
                    fd.append('image_' + idx, t.mainImgBlobDict[blobUrl]);
                    idx++;
                }
            });

            if (idx > 0) {

                fd.append('qty', idx.toString());

                t.busying = true;

                axios.post('pet/saveMultipleImage', fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                    if (res.data.status == 1) {
                        res.data.options.img_urls.forEach((url, i) => {
                            delete f.imgs[imgIndexs[i]].waitting_upload;
                            f.imgs[imgIndexs[i]].src = url;
                        });
                        _save();
                    }
                });

            }
            else
                _save();

        },

        copy() {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            axios.post('pet/copy', { 'pet_id': t.infoForm.id }).then(res => {
                t.busying = false;

                if (res.data.status == 1) {
                    A.toast('复制成功');
                }
            });

        },

        addOptionItem() {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            axios.post('option/addDefault', { 'pet_id': t.item.id }).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    let options = res.data.options.items;
                    options.forEach(option => {
                        option.labelForm = { 'name': '', id: null };
                        option.editing = false;
                        option.showActions = false
                        option.errMsg = "";
                        option.busying = false;
                    });
                    t.item.options.unshift(options[0]);
                }
            });
        },

        delOptionItem(option) {

            this.item.options.remove(option);

            axios.delete('option/' + option.id).then(res => {
                if (res.data.status == 1) {

                }
            });

        },

        editLabel(label, option) {
            let t = this;
            option.labelForm = getFields(label, ['id', 'name']);
            option.editing = label;
        },

        addLabel(option) {

            let t = this;

            if (t.busying)
                return;

            let f = option.labelForm;

            option.errMsg = "";

            if (!f.name) {
                option.errMsg = "请输入条目名称";
                return;
            }

            t.busying = true;

            axios.post('optionLabel', { 'option_id': option.id, 'name': f.name }).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    f.name = '';
                    option.labels.unshift(res.data.options);
                }
            });

        },

        moveUpItem(arr, index, type) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            let prev = arr[index - 1];
            let cur = arr[index];

            let idx = cur.index;

            cur.index = prev.index;
            prev.index = idx;

            arr[index - 1] = cur;
            arr[index] = prev;

            let f = {
                id: cur.id,
                index: cur.index,
                id2: prev.id,
                index2: prev.index
            };

            axios.post(type + '/saveIndex', f).then(res => {

                t.busying = false;

                t.$forceUpdate();

                if (res.data.status == 1) {

                }
            });

        },

        delLabel(label, o) {
            let t = this;

            A.safety('确定要删除该项吗？').then(res => {
                if (res.isConfirmed) {
                    o.labels.remove(label);
                    if (label.id)
                        axios.delete('optionLabel/' + label.id).then(res => {
                            if (res.data.status == 1) {

                            }
                        });

                }
            });
        },

        saveLabelForm(o) {

            let t = this;

            if (t.busying)
                return;

            let f = o.labelForm;

            t.errMsg = "";

            if (!f.name) {
                t.errMsg = "请填写条目名称";
                return;
            }

            o.busying = true;

            axios.put('optionLabel/' + f.id, f).then(res => {

                o.busying = false;

                if (res.data.status == 1) {

                    mirror(f, o.editing);

                    o.editing = false;
                    o.labelForm.id = null;
                    o.labelForm.name = '';

                    A.toast('保存成功');

                }
            });

        },

        saveOptionForm(o) {

            let t = this;

            if (t.busying)
                return;

            t.errMsg = "";

            if (!o.name) {
                t.errMsg = "请填写选项名称";
                return;
            }

            o.busying = true;

            axios.put('option/' + o.id, { 'name': o.name }).then(res => {

                o.busying = false;

                if (res.data.status == 1) {

                    A.toast('保存成功');

                }
            });

        },


        addTextBlock() {
            let t = this;

            t.item.contentArr.push({ 'type': 'text', 'content': '' });

            t.$forceUpdate();
        },

        addImageBlock() {
            let t = this;

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                t.descImgBlobDict[blobUrl] = file;

                t.item.contentArr.push({ 'type': 'img', 'content': blobUrl, 'waitting_upload': 1 });

                t.$forceUpdate();

                event.target['value'] = "";
            };

            ImageUploader.openSelector();

        },

        addVideoBlock() {
            let t = this;

            let exists = pick(t.item.contentArr, p => p.type == 'video' && p.waitting_upload == 1);

            if (exists.length > 0) {
                A.toast('一次只能上传一个视频，请保存后再添加更多', 1000, 'info');
                return;
            }

            VideoUploader.onSelectVideo = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                t.descVideoBlobDict[blobUrl] = file;

                t.item.contentArr.push({ 'type': 'video', 'content': blobUrl, 'waitting_upload': 1 });

                t.$forceUpdate();

                event.target['value'] = "";
            };

            VideoUploader.openSelector();

        },

        delBlock(arr, block) {
            arr.remove(block);
            this.$forceUpdate();
        },

        uploadPicture() {

            let t = this;

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                const image = document.getElementById('cropperImg');

                image.src = blobUrl;

                if (t.cropper) {
                    t.cropper.destroy();
                    t.cropper = null;
                }

                image.onload = () => {
                    t.cropper = new Cropper(image, {
                        aspectRatio: 1,
                        autoCropArea: 1,
                        crop(event) {
                        },
                    });
                    $('.cropper-item').show();
                }

                event.target['value'] = '';


            };

            ImageUploader.openSelector();
        },

        saveContentForm() {
            let t = this;

            t.$forceUpdate();

            if (t.busying)
                return;

            function _save() {

                let d = {
                    id: t.item.id,
                    content: '',
                };

                let arr;

                arr = [];

                t.item.contentArr.forEach(p => {
                    if (p.type == 'text' && p.content)
                        arr.push("<p>" + p.content + "</p>");
                    else if (p.type == 'img' && !p.waitting_upload)
                        arr.push("<p class=\"img-line\"><img class=\"upload-img\" src=\"" + 'https://i.shan-huan.com/res/' + p.content + "\"/></p>");
                    else if (p.type == 'video' && !p.waitting_upload)
                        arr.push("<p class=\"video-box\"><video class=\"upload-video\" controls src=\"" + 'https://i.shan-huan.com/res/' + p.content + "\"/></video></p>");

                });

                if (arr.length > 0)
                    d.content = arr.join("");

                t.busying = true;

                axios.put('pet/' + t.item.id, d).then(res => {
                    t.busying = false;
                    if (res.data.status == 1) {
                        A.toast('保存成功');
                    }
                });


            }

            let idx = 0;

            let fd = new FormData();

            let imgEntitys = [];

            let fd2 = new FormData();

            let videoEntitys = [];

            t.item.contentArr.forEach((p, i) => {
                if (p.waitting_upload == 1) {
                    let blobUrl = p.content;
                    if (p.type == 'img') {
                        imgEntitys.push(p);
                        fd.append('image_' + idx, t.descImgBlobDict[blobUrl]);
                        idx++;
                    } else if (p.type == 'video') {
                        videoEntitys.push(p);
                        fd2.append('video', t.descVideoBlobDict[blobUrl]);
                    }
                }
            });

            function _saveVideo() {

                t.busying = true;

                axios.post('pet/saveVideo4Details', fd2, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                    if (res.data.status == 1) {

                        delete videoEntitys[0].waitting_upload;

                        videoEntitys[0].content = res.data.options.video_url;

                        _save();
                    }
                });

            }

            if (imgEntitys.length > 0 || videoEntitys.length > 0) {

                fd.append('qty', idx.toString());

                t.busying = true;

                axios.post('pet/saveMultipleImage4Details', fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                    if (res.data.status == 1) {
                        res.data.options.img_urls.forEach((url, i) => {
                            delete imgEntitys[i].waitting_upload;
                            imgEntitys[i].content = url;
                        });
                        if (videoEntitys.length > 0)
                            _saveVideo();
                        else
                            _save();
                    }
                });
            }
            else {
                if (videoEntitys.length > 0)
                    _saveVideo();
                else
                    _save();
            }


        },

        closeCropper() {
            $('.cropper-item').hide();
        },

        confirmCropper() {

            let t = this;

            t.cropper.getCroppedCanvas({
                minHeight: 300,
                minWdith: 300,
                maxHeight: 600,
                maxWdith: 600,
                fillColor: '#fff',
            }).toBlob((blob) => {
                let blobUrl = getObjectURL(blob);
                t.mainImgBlobDict[blobUrl] = blob;
                t.infoForm.imgs.push({
                    waitting_upload: 1,
                    src: blobUrl,
                    showActions: false
                });
                $('.imageset .items').animate({ scrollLeft: 100000 }, 500);
                t.closeCropper();
            });
        },

        tapImg(img) {
            let t = this;

            if (t.lastImg != null)
                t.lastImg.showActions = false;

            img.showActions = true;

            t.lastImg = img;
        },

        onSelectCategory(e) {
            let t = this;
            t.categoryValue = e.value;
            t.infoForm.cate_id = t.categoryValue;
            t.infoForm.cate_name = t.dict.category[t.categoryValue].name;
            t.showCategorySelector = false;
        }

    },
    data() {
        return {
            view: 'info',

            dict: {
                category: {}
            },

            list: {
                category: [],
            },

            selector: {
                category: null,
            },

            item: {},

            lastImg: null,

            errMsg: "",

            busying: false,

            infoForm: {
                imgs: [],
                gender:1,
            },

            detailsForm: {

            },

            editorLang: null,

            descImgBlobDict: {},

            descVideoBlobDict: {},

            mainImgBlobDict: {},

            cropper: null,

            showCategorySelector: false,

            categoryValue: null,

            options: [
            ],
        };
    }
};
