import Vue from 'vue'
import Router from 'vue-router'

import Login from './login/login'

import Home from './home/home'
import StoreInfo from './storeInfo/storeInfo'
import Managers from './managers/managers'

import Orders from './orders/orders'
import Order from './order/order'

import List from './list/list'
import Item from './item/item'

import Category from './category/category'

import PetCategory from './petcategory/petcategory'
import PetList from './petlist/petlist'
import PetItem from './petitem/petitem'

import './style.css'

Vue.use(Router)
export default new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      window.lastPosition = savedPosition;
      return savedPosition
    } else {
      window.lastPosition = {
        x: 0,
        y: 0
      };
      return {
        x: 0,
        y: 0
      }
    }
  },
  routes: [{
      path: '',
      redirect: '/home'
    },
    {
      name: 'home',
      path: '/home',
      component: Home,
    },
    {
      name: 'orders',
      path: '/orders',
      component: Orders,
    },
    {
      name: 'order',
      path: '/order',
      component: Order,
    },
    {
      name: 'storeInfo',
      path: '/storeInfo',
      component: StoreInfo,
    },
    {
      name: 'list',
      path: '/list',
      component: List,
    },
    {
      name: 'item',
      path: '/item',
      component: Item,
    },
    {
      name: 'managers',
      path: '/managers',
      component: Managers,
    },
    {
      name: 'login',
      path: '/login',
      component: Login,
    },
    {
      name: 'category',
      path: '/category',
      component: Category,
    },
    {
      name: 'petcategory',
      path: '/petcategory',
      component: PetCategory,
    },
    {
      name: 'petlist',
      path: '/petlist',
      component: PetList,
    },
    {
      name: 'petitem',
      path: '/petitem',
      component: PetItem,
    },
  ],
})