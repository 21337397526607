

export default {
  name: "Action",
  props: {
    enabledBack: Boolean,
  },
  mounted() { },
  created() {
    var t = this;
    t.toggle = this.$root.toggle;
    if (localStorage['action_folded'])
      t.toggle.expandedNav = localStorage['action_folded'] == 1;
  },
  methods: {
    foldToggle() {
      this.toggle.expandedNav = !this.toggle.expandedNav;
      localStorage['action_folded'] = (this.toggle.expandedNav ? 1 : 0);
    }
  },
  data() {
    return {
      toggle: null
    };
  },
};
