

export default {
  name: "Nav",
  props: {},
  mounted() { },
  created() {
    var t = this;

    var reg = /\/(home|orders|list|storeInfo)/i;

    t.$router.beforeEach((to, from, next) => {

      var r = reg.exec(to.path);

      if (r && r.length > 0) {
        t.page = r[1];
        t.showNav = true;
        t.afterClickMenu();
      }
      else
        t.showNav = false;

      next();
    });

    var r = reg.exec(location.pathname);

    if (r && r.length > 0) {
      t.page = r[1];
      t.showNav = true;
      t.afterClickMenu();
    }
    else
      t.showNav = false;

  },
  methods: {
    afterClickMenu() {

      let t = this;

      setTimeout(() => {

        var menu = document.querySelector(".menu");
        var menuBorder = menu.querySelector(".menu__border");
        var element = document.querySelector('.menu__item[data-page=' + t.page + ']');

        if (document.querySelector('.menu__item.active'))
          document.querySelector('.menu__item.active').classList.remove('active');
        element.classList.add('active');
        var offsetActiveItem = element.getBoundingClientRect();
        var left = Math.floor(offsetActiveItem.left - menu['offsetLeft'] - (menuBorder.offsetWidth -
          offsetActiveItem
            .width) / 2) + "px";
        menuBorder.style.transform = `translate3d(${left}, 0 , 0)`;

      }, 50);

    },
    go(page) {

      if (page == this.page) return;

      this.page = page;

      this.$router.replace(page);

    }
  },
  data() {
    return {
      page: "",
      showNav: false,
    };
  },
  components: {
  },
};
