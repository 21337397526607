


export default {

    activated() {

        if (B.storeInfoPage) {
            let modal = B.storeInfoPage.modal;

            if (modal) {
                this.openModal(modal);
            }

            delete B.storeInfoPage;
        }

    },
    created() {

        let t = this;

        t.dict.role = listToDict(t.list.role);

        axios.get('storeConfig/getStats').then(res => {
            if (res.data.status == 1) {
                t.stats = res.data.options.stats;
                t.c = res.data.options.config;
                t.infoForm = getFields(t.c, ['name', 'logo_url', 'status', 'summary', 'closing_title', 'closing_desc']);
            }
        });

    },
    methods: {

        goPage(page) {
            this.$router.push('/' + page);
        },

        logout() {

            let t = this;

            A.safety('确认要退出登录吗？').then(res => {
                if (res.isConfirmed) {
                    localStorage.removeItem('_m');
                    t.U = null;
                    t.$router.replace('/login');
                }
            });
        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        savePasswordForm() {

            let t = this;

            let f = t.passwordForm;

            if (t.busying)
                return;

            t.errMsg = "";

            if (!f.old_password) {
                t.errMsg = "请输入旧密码";
                return;
            }

            if (!f.new_password) {
                t.errMsg = "请输入新密码";
                return;
            }

            if (!f.confirm_password) {
                t.errMsg = "请再次输入新密码";
                return;
            }

            if (f.new_password != f.confirm_password) {
                t.errMsg = "两次输入密码不一致";
                return;
            }

            t.busying = true;

            axios.post('manager/modifyPassword', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {

                    A.show('您的密码已更新，请牢记。', '操作成功', 'success');

                    t.closeModal();

                }
                else
                    t.errMsg = "旧密码错误";
            });



        },

        saveInfoForm() {

            let t = this;

            if (t.busying)
                return;

            let f = t.infoForm;

            t.errMsg = "";

            if (!f.logo_url && !f.base64_logo) {
                t.errMsg = "请上传店铺Logo";
                return;
            }

            if (!f.name) {
                t.errMsg = "请输入店铺名称";
                return;
            }

            if (!f.summary) {
                t.errMsg = "请输入店铺简介";
                return;
            }

            if (f.status != 1 && !f.closing_title) {
                t.errMsg = "请输入歇业标题";
                return;
            }

            if (f.status != 1 && !f.closing_desc) {
                t.errMsg = "请输入歇业说明";
                return;
            }

            t.busying = true;

            axios.put('storeConfig/' + t.c.id, f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    A.toast('保存成功');
                }
            });

        },

        startUpload() {
            var t = this;
            var e = document.querySelector("#fImg");
            e.onchange = function (img) {
                img = this;
                compressImg(img.files[0], { max_height: 800, max_width: 800 }, function (base64) {
                    t.infoForm.base64_logo = base64;
                });
            };
            e.value = "";
            e.click();
        },

        moveUpItem(arr, index, type) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            let prev = arr[index - 1];
            let cur = arr[index];

            let idx = cur.index;

            cur.index = prev.index;
            prev.index = idx;

            arr[index - 1] = cur;
            arr[index] = prev;

            let f = {
                id: cur.id,
                index: cur.index,
                id2: prev.id,
                index2: prev.index
            };

            axios.post(type + '/saveIndex', f).then(res => {

                t.busying = false;

                t.$forceUpdate();

                if (res.data.status == 1) {

                }
            });

        },


    },
    data() {
        return {
            modal: '',
            dict: {},
            list: {
                role: [
                    {
                        'id': 101,
                        'name': '店铺超管'
                    },
                    {
                        'id': 102,
                        'name': '店铺运营'
                    }
                ],
            },
            stats: {
                order: 0,
                goods: 0,
                category: 0,
                manager: 0,
                activity: 0
            },

            errMsg: "",
            passwordForm: {
                old_password: "",
                new_password: "",
                confirm_password: "",
            },
            infoForm: {
                base64_logo: '',
                status: 1,
                logo_url: "",
                name: "",
                summary: "",
                desc: "",
                closing_title: "",
                closing_desc: "",
            },
            categoryForm: {
                name: ''
            },
            c: null,
        };
    },
};
