


export default {

    activated() {
    },
    methods: {

        login() {

            let t = this;

            let f = t.loginForm;

            if (t.busying)
                return;

            t.errMsg = "";

            if (!f.username) {
                t.errMsg = "请输入用户名";
                return;
            }

            if (!f.password) {
                t.errMsg = "请输入密码";
                return;
            }

            t.busying = true;

            axios.post('manager/login', f).then(res => {
                t.busying = false;
                
                if (res.data.status == 1) {
                    let U = res.data.options.item;
                    mirror(U, t.U);
                    S.set('_m', U);
                    t.afterLogin();
                    t.$router.replace('/storeInfo');
                }
                else if (res.data.status == -1)
                    t.errMsg = "用户民不存在";
                else if (res.data.status == 0)
                    t.errMsg = "密码错误";
            });
        }

    },
    data() {
        return {
            view: "",
            errMsg: "",

            busying: false,

            loginForm: {
                username: "",
                password: "",
            }
        };
    },
};
