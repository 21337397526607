import Vue from 'vue'
import Index from './Index'
import router from './router'

import trans from './components/trans'
import action from "./components/action";
import loader from "./components/loader";

import 'animate.css';

Vue.config.productionTip = false

Vue.component('trans', trans);
Vue.component('action', action);
Vue.component('loader', loader);

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

window.B = {};

window.S = {
  'set': function (key, value) {

    if (typeof (value) == 'object')
      value = JSON.stringify(value);

    localStorage.setItem(key, value);

  },
  'get': function (key) {
    var value = localStorage.getItem(key);

    try {
      return JSON.parse(value);
    } catch (ex) {
      return value;
    }
  },
  'remove': function (key) {
    if (typeof key == 'object' && key.hasOwnProperty('length'))
      for (var i = 0; i < key.length; i++) {
        localStorage.removeItem(key[i]);
      }
    else
      localStorage.removeItem(key);
  },
  'clear': function () {
    var data = getFields(localStorage, ['lang'], false);
    localStorage.clear();
    for (var name in data) {
      localStorage.setItem(name, data[name]);
    }
  }
};

window.A = {
  'show': function (body, title, type) {
    return Swal.mixin({
      customClass: {
        confirmButton: 'btn-lg btn-orange',
        cancelButton: 'btn-sm btn-dark mr10'
      },
      confirmButtonText: '我知道了',
      showCancelButton: false,
      buttonsStyling: false,
    }).fire(title, body, type || '');
  },
  'err': function (body, title) {
    return Swal.mixin({
      customClass: {
        confirmButton: 'btn-lg btn-dark',
        cancelButton: 'btn-sm btn-dark mr10'
      },
      confirmButtonText: '我知道了',
      showCancelButton: false,
      buttonsStyling: false,
    }).fire(title, body, 'error');
  },
  'toast': function (text, duration, type) {
    return Swal.mixin({
      timer: duration || 1000,
      timerProgressBar: true,
      buttonsStyling: false,
      showCancelButton: false,
      showConfirmButton: false
    }).fire(text, '', type || 'success');
  },
  'safety': function (content, type, onlyAlert) {

    return Swal.mixin({
      customClass: {
        confirmButton: 'btn-lg btn-orange',
        cancelButton: 'btn-sm btn-dark mr10'
      },
      confirmButtonText: '我知道了',
      cancelButtonText: '取消',
      buttonsStyling: false,
      showCancelButton: !onlyAlert
    }).fire('安全确认', content, type || 'warning');

  }
};

Date.prototype.format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    'S': this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
  return fmt;
};

Array.prototype.remove = function (item) {
  var index = this.indexOf(item);
  if (index > -1)
    this.splice(index, 1);
  return this;
};

Array.prototype.removeAll = function (items) {

  for (var i = 0; i < items.length; i++) {
    var item = items[i];
    var index = this.indexOf(item);
    if (index > -1)
      this.splice(index, 1);
  }
  return this;
};

Array.prototype.append = function (items) {
  for (var i = 0; i < items.length; i++) {
    var item = items[i];
    var index = this.indexOf(item);
    if (index < 0)
      this.push(item);
  }
  return this;
};

window.getFields = function (model, fields, isInclude) {
  isInclude = isInclude == undefined ? true : isInclude;

  var result = {};

  if (isInclude) {
    for (var i = 0; i < fields.length; i++) {

      var field = fields[i];

      if (typeof field == "string") {
        if (model[field] != null)
          result[field] = model[field];
      } else if (typeof field == "object") {
        for (var source in field) {
          var fieldName = field[source];
          result[fieldName] = model[source];
          break;
        }
      }
    }
  } else {
    for (var name in model) {

      var value = model[name];

      if (fields.indexOf(name) < 0 && value != null)
        result[name] = value;

    }
  }

  return result;
}

window.listToDict = function (list, keyName) {

  keyName = keyName || 'id';

  var dict = {};

  if (list && list.length > 0) {
    for (var i = 0; i < list.length; i++) {
      var item = list[i];
      dict[item[keyName]] = item;
    }
  }

  return dict;

}

window.listToDict2 = function (list, keyName, valueName) {

  keyName = keyName || 'id';

  var dict = {};

  if (list && list.length > 0) {
    for (var i = 0; i < list.length; i++) {
      var item = list[i];
      dict[item[keyName]] = item[valueName];
    }
  }

  return dict;

}

window.mirror = function (source, target) {

  target = target || {};

  for (var name in source) {
    target[name] = source[name];
  }

  return target;
}

window.combineObject = function (o1, o2) {
  var r = {};

  for (var key in o1) {
    r[key] = o1[key];
  }

  for (var key in o2) {
    r[key] = o2[key];
  }

  return r;
}

window.getURLParams = function () {

  if (location.search && location.search.indexOf('?') > -1) {

    var r = location.search.split('?')[1].split('&');

    var obj = {};

    if (r.length > 0) {

      r.forEach(function (item) {
        var _tmp = item.split('=');
        obj[_tmp[0]] = _tmp[1];
      });

      return obj;
    }

  }

  return {};

}

window.compressImg = function (file, options, callback) {
  var imgname = file.name;
  var imgtype = (imgname.substring(imgname.lastIndexOf('.') + 1)).toLowerCase();
  if (imgtype == 'jpg' || imgtype == 'jpeg') {
    imgtype = 'image/jpeg';
  } else {
    imgtype = 'image/png';
  }
  // 用FileReader读取文件
  var reader = new FileReader();
  // 将图片读取为base64
  reader.readAsDataURL(file);
  reader.onload = function (evt) {
    var base64 = evt.target.result;
    window._compressImg(base64, options, callback, imgtype);
  }
};
window._compressImg = function (base64, options, callback, imgtype) {
  imgtype = imgtype || 'image/png';
  // 创建图片对象
  var img = new Image();
  // 用图片对象加载读入的base64
  img.src = base64;



  img.onload = function () {
    var t = this,
      canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');

    var maxWidth = options.max_width;
    var maxHeight = options.max_height;

    var targetWidth, targetHeight;

    if (img.width > maxWidth && img.height > maxHeight) {
      const rate = Math.min(maxWidth / img.width, maxHeight / img.height)
      targetWidth = img.width * rate
      targetHeight = img.height * rate
    } else if (img.width > maxWidth) {
      targetWidth = maxWidth
      targetHeight = (maxWidth / img.width) * img.height
    } else if (img.height > maxHeight) {
      targetHeight = maxHeight
      targetWidth = (maxHeight / img.height) * img.width
    } else {
      targetWidth = img.width
      targetHeight = img.height
    }

    canvas.setAttribute('width', targetWidth);
    canvas.setAttribute('height', targetHeight);
    // 将图片画入canvas
    ctx.drawImage(t, 0, 0, targetWidth, targetHeight);

    // 压缩到指定体积以下（M）
    if (options.size) {
      var scale = 0.9;
      (function f(scale) {
        if (base64.length / 1024 / 1024 > options.size && scale > 0) {
          base64 = canvas.toDataURL(imgtype, scale);
          scale = scale - 0.1;
          f(scale);
        } else {
          callback(base64);

        }
      })(scale);
    } else if (options.scale) {
      // 按比率压缩
      base64 = canvas.toDataURL(imgtype, options.scale);
      callback(base64);
    } else if (options.max_height || options.max_height) {
      base64 = canvas.toDataURL(imgtype, 1);
      callback(base64);
    }

  }
}

window.getObjectURL = function (file) {
  var url = null;
  if (window.createObjectURL != undefined) { //basic
    url = window.createObjectURL(file);
  } else if (window.URL != undefined) { //mozilla(firefox)兼容火狐
    url = window.URL.createObjectURL(file);
  } else if (window.webkitURL != undefined) { //webkit or chrome
    url = window.webkitURL.createObjectURL(file);
  }
  return url;
}

window.filter = function (arr, fn) {
  var res = [];
  for (var i = 0; i < arr.length; i++) {
    if (fn(arr[i]))
      res.push(arr[i]);
  }
  return res;
}

window.pluck = function (items, key) {
  var arr = [];
  for (var i = 0; i < items.length; i++) {
    arr.push(items[i][key]);
  }
  return arr;
}

window.pick = function (items, fields) {
  var arr = [];
  for (var i = 0; i < items.length; i++) {
    let item = {};
    for (var j = 0; j < fields.length; j++) {
      item[fields[j]] = items[i][fields[j]];
    }
    arr.push(item);
  }
  return arr;
}

window.ImageUploader = {
  'openSelector': function () {
    document.getElementById('fImage').click();
  },
  'onSelectFile': function () {

    var inputFile = window.event.target;

    var file = inputFile.files[0];

    if (!file)
      return;

    if (file.type.match('image.*')) {
      if (ImageUploader.onSelectImage)
        ImageUploader.onSelectImage(file);
    } else {
      inputFile.value = '';
    }
  },
  'onSelectImage': function () {

  }
};
window.VideoUploader = {
  'openSelector': function (multiple) {

    var imgEle = document.getElementById('fVideo');

    if (multiple)
      imgEle.setAttribute('multiple', 'multiple');
    else
      imgEle.removeAttribute('multiple');

    imgEle.click();

  },
  'onSelectFile': function () {

    var inputFile = window.event.target;

    var file = inputFile.files[0];

    if (!file)
      return;

    var multiple = window.event.target.getAttribute('multiple') == 'multiple';

    if (multiple && VideoUploader.onSelectMultipleVideo) {
      VideoUploader.onSelectMultipleVideo(inputFile.files);
    }
    if (!multiple && VideoUploader.onSelectVideo) {

      if (file.type.match('video.*'))
        VideoUploader.onSelectVideo(file);
      else
        document.getElementById('fVideo').value = '';

    }

    inputFile.value = '';

  },
  'onSelectVideo': function () {},
  'onSelectMultipleVideo': function (files) {}
};

$(document).ready(function () {

  $(document).delegate('.date-range', 'click', function () {
    var t = this;

    t.classList.add('open');
    setTimeout(function () {
      t.classList.add('show');
    }, 400);
  });

  $(document).delegate('.search', 'click', function () {
    var self = $(this);
    self.addClass('open');
    setTimeout(function () {
      self.find('input').focus();
    }, 400);
  });

  $(document).on('click', function (e) {

    if (!e.target.classList.contains('search') && $(e.target).parents('.search').length == 0) {
      $('.search').removeClass('open');
    }

    if (!e.target.classList.contains('date-range') && $(e.target).parents('.date-range').length == 0) {
      $('.date-range').removeClass('show');
      $('.date-range').removeClass('open');
    }

  });

  $(document).delegate('input,div[contenteditable="true"],textarea', 'focus', function () {
    document.body.classList.add('typing');
  });

  $(document).delegate('input,div[contenteditable="true"],textarea', 'blur', function () {
    document.body.classList.remove('typing');
  });

});

Vue.prototype.url = function (url) {
  if (!url)
    return '/assets/img/placeholder.png';
  if(url.indexOf(',') > -1)
    url = url.split(',')[0];
  if (url.indexOf('/assets') > -1 || url.indexOf('blob:') > -1 || url.indexOf('http') == 0)
    return url;
  else {
    if (/location/.test(location.host))
      return '//localhost/mll/api/public/res/' + url;
    else
      return '//i.shan-huan.com/res/' + url;
  }

}

Vue.prototype.url1 = function (urls) {
  return Vue.prototype.url(urls.split(',')[0]);
}

Vue.prototype.thumb = function (url) {
  if(url.indexOf(',') > -1)
    url = url.split(',')[0];
  return Vue.prototype.url(url.replace('img/', 'thumb/'));
}

Vue.prototype.thumb1 = function (urls) {
  return Vue.prototype.url(urls.split(',')[0]).replace('img/', 'thumb/');
}

Vue.prototype.makeSort = function (sort) {
  let order = [];
  for (let key in sort) {
    if (sort[key] > 0)
      order.push(key + '-' + sort[key]);
  }
  return order.join(',');
}

Vue.prototype.sortToggle = function (sort, key) {

  let old = sort[key];

  let value;

  for (let _key in sort) {
    sort[_key] = 0;
  }

  if (old == 0)
    value = 2;
  else if (old == 1)
    value = 2;
  else if (old == 2)
    value = 1;

  sort[key] = value;

}

Vue.prototype.favoriteToggle = function (item) {

  item.favorited = !item.favorited;

  axios.post('user/favoriteToggle', {
    goods_id: item.id
  }).then(res => {
    if (res.data.status == 1) {

    }
  });

}

Vue.prototype.stopPropagation = function () {
  event.stopPropagation();
}

Vue.prototype.initSharing = function (title, path, imageUrl) {
  if (window.wx && wx.miniProgram)
    wx.miniProgram.postMessage({
      data: {
        'msg_type': 'sharing_params',
        'title': title ? title : '猫来啦',
        'path': path ? path : ('/pages/index/index?gid=' + Vue.prototype.U.id),
        'imageUrl': imageUrl ? imageUrl : 'https://i.shan-huan.com/res/img/sharing/default.png'
      }
    });
}

Vue.prototype.showSharingGuide = function () {
  Swal.mixin({
    customClass: {
      confirmButton: 'btn-lg btn-dark ',
    },
    confirmButtonText: '我知道了',
    showCancelButton: false,
  }).fire('请继续操作', '1. 请点击右上角的 “···”<br> 2. 再点击 “发送给朋友”', '').then(res => {});
}

Vue.filter('url', function (url) {
  return Vue.prototype.url(url);
});

Vue.filter('number', function (num) {
  var suffix = '';

  num = Number(num);
  if (num.toString().indexOf('.') > -1) {
    suffix = '.' + num.toString().split('.')[1];
    num = num.toString().split('.')[0];
  }
  var result = [],
    counter = 0;
  num = (num || 0).toString().split('');
  for (var i = num.length - 1; i >= 0; i--) {
    counter++;
    result.unshift(num[i]);
    if (!(counter % 3) && i != 0) {
      result.unshift(',');
    }
  }
  return result.join('') + suffix;
});

Vue.filter('friendly', function (date) {
  var d = new Date(date);
  return d.format('MM/dd hh:mm');
});

Vue.filter('friendly2', function (date) {
  return date.replace(':', '时') + '分';
});

Vue.filter('friendly3', function (date) {
  return new Date(date).format('MM月dd日');
});


Vue.filter('plainText', function (html) {
  if (!html) return '';
  var d = document.createElement("div");
  d.innerHTML = html;
  return d.innerText;
});

Vue.filter('n', function (number) {
  return Number(number);
});

Vue.filter('d', function (date) {
  return new Date(date).format('M/d hh:mm')
});

Vue.filter('d2', function (date) {
  return new Date(date).format('M/d')
});

Vue.filter('d3', function (date) {
  return new Date(date).format('h:s')
});

Vue.filter('days', function (date) {
  return Math.ceil(((new Date()) - (new Date('2022-08-15 19:31:39'))) / (24 * 60 * 1000 * 60));
});

Vue.filter('price', function (price) {
  return Number(price);
});

Vue.filter('firstline', function (text) {
  if (text) {
    return text.split("\n")[0];
  }
  return "";
});

Vue.prototype.hl = function (text, keywords) {
  if (text) {
    return text.replace(keywords, '<b class="tr">' + keywords + '</b>')
  }
  return "";
};

Vue.prototype.br = function (text) {
  if (text) {
    return text.replace(/\n/g, "<br>");
  }
  return "";
}

Vue.filter('br', Vue.prototype.br);

new Vue({
  render: h => h(Index),
  eventHub: new Vue(),
  router
}).$mount('#app');

if (/iPhone/.test(window.navigator.userAgent)) {
  document.body.classList.add('platform-ios');
}